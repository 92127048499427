import { createAwsAxiosInstance, feedData } from '@afrigis/aws-fuel-prices';
import { GettersUtility, ModuleUtilities, MutationsUtility } from '@afrigis/vuex-utilities';
import Constants from '@/store/storeConstants';

const {
  ACTIONS,
  STATE_VARS,
  MUTATIONS,
  AwsAxiosStore: AAS,
  FuelPricesStore: FPS,
} = Constants;

const initialState = () => ({
  [STATE_VARS.DATA]: null,
  [STATE_VARS.IS_LOADING]: false,
  [FPS.StateVars.SelectedFuelType]: null,
  [FPS.StateVars.SelectedFuelPrice]: 0.00,
});

const STATE_PROPS_TO_EXPOSE = [
  ...Object.values(STATE_VARS),
  ...Object.values(FPS.StateVars),
];

const actions = {
  [ACTIONS.LOAD]: async (context) => {
    context.commit(MUTATIONS.SetIsLoading, true);
    context.commit(MUTATIONS.SetData, []);

    try {
      const { Token, Key } = context.rootState[AAS.Name];
      const axiosInstance = await createAwsAxiosInstance(Token, Key);
      const { data: { code, result } } = await feedData({ axiosInstance });
      if (code !== 200) {
        throw new Error('Failed to load feed data.');
      }
      context.commit(MUTATIONS.SetData, result);
    } catch (error) {
      throw new Error(`Could not load data - ${error}`);
    } finally {
      context.commit(MUTATIONS.SetIsLoading, false);
    }
  },
};

const state = initialState();

const getters = {
  ...GettersUtility.scaffold(state, STATE_PROPS_TO_EXPOSE),
};

const mutations = {
  ...MutationsUtility.scaffold(state, STATE_PROPS_TO_EXPOSE),
  [MUTATIONS.Reset]: ModuleUtilities.ResetToInitial(initialState),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
