<template>
  <div>
    <b-button v-b-toggle.turn-by-turn-collapse>
      <b-icon-file-earmark-text />
      <span class="pl-3">Directions </span>
      <b-icon-arrow-bar-down />
    </b-button>
      <b-collapse id="turn-by-turn-collapse">
        <b-card class="mt-3">
          <p class="font-weight-bold">Total Distance: {{ TravelOptionTotalLength }} km</p>
          <p class="font-weight-bold">
            Estimated travel time: {{ TravelOptionTotalTravelTime }} hrs
          </p>
          <dl class="row no-gutters">
            <dt class="col-10 font-weight-bold">
              Instruction
            </dt>
            <dd class="col-2 font-weight-bold">
              Distance
            </dd>
            <template v-for="(direction, index) in TravelOptionDirections">
              <dt :class="definitionClasses(index, true)" :key="`dt-${index}`">
                {{ direction.instruction }}
              </dt>
              <dd :class="definitionClasses(index, false)" :key="`dd-${index}`">
                {{ direction.distance }}
              </dd>
            </template>
          </dl>
        </b-card>
      </b-collapse>
  </div>
</template>

<script>
import {
  mapGetters,
} from 'vuex';

import {
  BButton,
  BCard,
  BCollapse,
  BIconArrowBarDown,
  BIconFileEarmarkText,
} from 'bootstrap-vue';

import Constants from '@/store/storeConstants';

const {
  JourneyStore: JS,
} = Constants;

export default {
  components: {
    BButton,
    BCard,
    BCollapse,
    BIconArrowBarDown,
    BIconFileEarmarkText,
  },
  computed: {
    ...mapGetters({
      TravelOptionDirections: `${JS.Name}/${JS.Getters.TravelOptionDirections}`,
      TravelOptionTotalLength: `${JS.Name}/${JS.Getters.TravelOptionTotalLength}`,
      TravelOptionTotalTravelTime: `${JS.Name}/${JS.Getters.TravelOptionTotalTravelTime}`,
    }),
  },
  methods: {
    definitionClasses(index, isInstruction) {
      const arr = ['pt-1', 'pb-1', 'm-0', 'wrap'];
      if (index % 2 === 0) {
        arr.push('bg-light');
      }
      if (isInstruction) {
        arr.push('col-10');
      } else {
        arr.push('col-2');
      }
      return arr;
    },
  },
  name: 'TurnbyTurnDirection',
};
</script>

<style></style>
