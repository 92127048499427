const tollIconsUrl = (label) => {
  if (label === 'MAINLINE') {
    return {
      CLASS_1: [
        require('@/assets/icons/vehicle_class/toll_class_1.jpg'),
        require('@/assets/icons/vehicle_class/toll_class_1A.jpg'),
      ],
      CLASS_2: [
        require('@/assets/icons/vehicle_class/toll_class_2.jpg'),
      ],
      CLASS_3: [
        require('@/assets/icons/vehicle_class/toll_class_3.jpg'),
      ],
      CLASS_4: [
        require('@/assets/icons/vehicle_class/toll_class_4.jpg'),
      ],
    };
  }
  return {
    CLASS_1: [
      require('@/assets/icons/vehicle_class/e_toll_class_1.jpg'),
      require('@/assets/icons/vehicle_class/e_toll_class_1A.jpg'),
    ],
    CLASS_2: [
      require('@/assets/icons/vehicle_class/e_toll_class_2.jpg'),
    ],
    CLASS_3: [
      require('@/assets/icons/vehicle_class/e_toll_class_3.jpg'),
    ],
    CLASS_4: [
      require('@/assets/icons/vehicle_class/e_toll_class_4.jpg'),
    ],
  };
};

export default {
  tollIconsUrl,
};
