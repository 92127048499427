<template>
  <b-container fluid>
    <b-row>
      <b-col md="4" class="p-3 text-left">
        <h1>Trip Cost Calculator</h1>
      </b-col>
      <b-col md="4" class="ml-auto p-3 text-right appCtrlButtons">
        <b-button
          @click="shareViaWebShare"
          title="Share This App"
          variant="outline-secondary"
        >
          <b-icon-share />
        </b-button>
        <b-modal
          id="modal-1"
          title="Share This App"
          ref="fallback-modal"
          @ok="handleOk"
        >
          <b-form-textarea
            id="share-fallback"
            ref="toCopy"
            v-model="shareUrl"
            rows="1"
            max-rows="6"
          />
          <template #modal-footer="{ ok }">
            <b-button
              size="sm"
              variant="primary"
              @click="ok()"
            >
              Copy URL to Clipboard
            </b-button>
          </template>
        </b-modal>
        <b-button
          @click="startTour"
          variant="outline-secondary"
        >
          Start Tour
        </b-button>
      </b-col>
    </b-row>
    <contact-expert />
    <v-tour
      :callbacks="tourCallbacks"
      class="trip-cost-tour"
      name="tripCostTour"
      :options="tourOptions"
      :steps="tripCostTourSteps"
    />
    <b-row>
      <b-col class="mb-3" lg="4">
        <address-search
          class="address-search"
          :is-disabled="isShowingTour"
        />
        <travel-option
          :is-disabled="isShowingTour"
          class="travel-option"
          v-if="hasData"
        />
        <cost-estimation-calculator
          class="cost-estimation mb-3"
          v-if="hasData && !isWalkingTravelOption"
        />
        <turn-by-turn-direction
          class="turn-by-turn-instruction"
          v-if="hasData"
        />
      </b-col>
      <b-col class="mb-3" lg="4">
        <leaflet-map
          class="map-container"
          :popup-template-id="tollGatepopupTemplateId"
        />
        <toll-gate-info-pop
          :templateId="tollGatepopupTemplateId"
        />
      </b-col>
      <b-col class="mb-5" lg="4">
        <information />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import {
  BButton,
  BCol,
  BContainer,
  BIconShare,
  BFormTextarea,
  BRow,
} from 'bootstrap-vue';

import AddressSearch from '@/components/AddressSearch.vue';
import ContactExpert from '@/components/ContactExpert.vue';
import CostEstimationCalculator from '@/components/cost/Calculator.vue';
import Information from '@/components/Information.vue';
import LeafletMap from '@/components/LeafletMap.vue';
import TravelOption from '@/components/TravelOption.vue';
import TurnByTurnDirection from '@/components/TurnbyTurnDirection.vue';

import Constants from '@/store/storeConstants';
import TollGateInfoPop from '@/components/TollGateInfoPop.vue';

const {
  ACTIONS,
  GETTERS,
  AddressSearchStore: ASS,
  AwsAxiosStore: AAS,
  FuelPricesStore: FPS,
  JourneyStore: JS,
  MUTATIONS,
} = Constants;

const mockTripData = require('@/data/mockTripData.json');

export default {
  components: {
    AddressSearch,
    BButton,
    BCol,
    BContainer,
    BIconShare,
    BFormTextarea,
    BRow,
    ContactExpert,
    CostEstimationCalculator,
    LeafletMap,
    Information,
    TravelOption,
    TurnByTurnDirection,
    TollGateInfoPop,
  },
  computed: {
    ...mapGetters({
      AwsAxiosInstance: `${AAS.Name}/${GETTERS.Data}`,
      RoutingData: `${JS.Name}/${GETTERS.Data}`,
      SelectedTravelOption: `${JS.Name}/${JS.Getters.SelectedTravelOption}`,
    }),
    hasData() {
      return !(!this.RoutingData || !this.RoutingData.length);
    },
    isWalkingTravelOption() {
      return this.SelectedTravelOption === 'WALKING';
    },
    shareUrl() {
      const { origin, pathname } = window.location;
      return `${origin}${pathname}`;
    },
    tourCallbacks() {
      return {
        onFinish: this.resetTour,
        onSkip: this.resetTour,
      };
    },
    tourOptions() {
      return {
        highlight: true,
        labels: {
          buttonSkip: 'Skip tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Thanks',
        },
      };
    },
    tourSampleDestinationResult: () => ({
      formatted_address: '446 Rigel Avenue South, Erasmusrand, Pretoria, Gauteng, 0181',
      location: {
        lat: -25.808699,
        lng: 28.256111,
      },
    }),
    tourSampleOriginResult: () => ({
      formatted_address: 'Menlyn, Pretoria, Gauteng, 0063',
      location: {
        lat: -25.786148,
        lng: 28.277637,
      },
    }),
    tourTripSampleResults: () => mockTripData,
    tollGatepopupTemplateId: () => 'toll-gate-pop-template-id',
    tripCostTourSteps() {
      return [
        {
          content:
            'Provide an origin and destination addresses for trip cost estimation.',
          header: {
            title: 'Address Search',
          },
          params: {
            placement: 'auto',
          },
          target: '.address-search',
        },
        {
          before: () => new Promise((resolve) => {
            this.setMockTourData(resolve);
          }),
          content: 'Select the Travel Option - fastest, shortest and walking trips.',
          header: {
            title: 'Travel Options',
          },
          params: {
            placement: 'auto',
          },
          target: '.travel-option',
        },
        {
          before: () => new Promise((resolve) => {
            this.setMockTourData(resolve);
          }),
          content: 'See Trip Cost Estimation Calculations.',
          header: {
            title: 'Cost Estimation Calculation',
          },
          params: {
            placement: 'auto',
          },
          target: '.cost-estimation',
        },
        {
          before: () => new Promise((resolve) => {
            this.setMockTourData(resolve);
          }),
          content: 'View Trip Turn by Turn Instructions, based on the trip selected.',
          header: {
            title: 'Turn by Turn Instructions',
          },
          params: {
            placement: 'auto',
          },
          target: '.turn-by-turn-instruction',
        },
        {
          content: 'View Trips on a map.',
          header: {
            title: 'View trips on the map',
          },
          params: {
            placement: 'auto',
          },
          target: '.map-container',
        },
      ];
    },
  },
  destroyed() {
    this.ResetAddressSearchStoreToInitialState();
    this.ResetJourneyStoreStoreToInitialState();
    window.removeEventListener('iris:share-capability', this.shareViaWebShare);
    window.removeEventListener('iris:start-tour', this.startTour);
  },
  data() {
    return {
      isShowingTour: false,
    };
  },
  methods: {
    ...mapActions({
      LoadAwsAuthentication: `${AAS.Name}/${AAS.Actions.LoadAwsAuthentication}`,
      LoadFuelPrices: `${FPS.Name}/${ACTIONS.LOAD}`,
    }),
    ...mapMutations({
      ResetAddressSearchStoreToInitialState: `${ASS.Name}/${MUTATIONS.RESET}`,
      ResetJourneyStoreStoreToInitialState: `${JS.Name}/${MUTATIONS.RESET}`,
      SetAxiosInstance: `${ASS.Name}/${ASS.Mutations.SetAxiosInstance}`,
      SetFuelPriceAxiosInstance: `${FPS.Name}/${FPS.Mutations.SetAxiosInstance}`,
      SetDetailedDestinationResults: `${ASS.Name}/${ASS.Mutations.SetDetailedDestinationResults}`,
      SetDetailedOriginResults: `${ASS.Name}/${ASS.Mutations.SetDetailedOriginResults}`,
      SetTripData: `${JS.Name}/${MUTATIONS.SetData}`,
    }),
    isTourOn() {
      const { tour } = this.$route.query;
      if (!tour) {
        return false;
      }
      return /^true$/i.test(tour.toLowerCase());
    },
    handleOk() {
      const url = this.$refs.toCopy;
      url.innerHTML = this.shareUrl;
      url.select();
      document.execCommand('copy');
      this.$bvModal.msgBoxOk('URL Copied to Clipboard successfully', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      });
    },
    resetTour() {
      this.SetDetailedDestinationResults(null);
      this.SetDetailedOriginResults(null);
      this.SetTripData(null);
      this.isShowingTour = false;
    },
    setMockTourData(resolve) {
      this.SetDetailedDestinationResults(this.tourSampleDestinationResult);
      this.SetDetailedOriginResults(this.tourSampleOriginResult);
      this.SetTripData(this.tourTripSampleResults);
      resolve();
    },
    shareViaWebShare() {
      if (navigator.share) {
        navigator
          .share({
            title: document.title,
            url: this.shareUrl,
          })
          .then()
          .catch(() => {
            // Handle the error!
          });
      } else {
        this.$refs['fallback-modal'].show();
      }
    },
    startTour() {
      this.$tours.tripCostTour.start();
      this.isShowingTour = true;
    },
    tourConfigure() {
      const hasSeenTour = JSON.parse(
        localStorage.getItem('hasSeenTripCostTour'),
      );
      if (!hasSeenTour || this.isTourOn()) {
        this.startTour();
        localStorage.setItem('hasSeenTripCostTour', true);
      }
    },
  },
  async mounted() {
    window.addEventListener('iris:share-capability', this.shareViaWebShare);
    window.addEventListener('iris:start-tour', this.startTour);
    this.tourConfigure();
    await this.LoadAwsAuthentication();
    this.SetAxiosInstance(this.AwsAxiosInstance);
    this.SetFuelPriceAxiosInstance(this.AwsAxiosInstance);
    this.LoadFuelPrices();
  },
  name: 'TripCost',
};
</script>

<style src="vue-tour/dist/vue-tour.css"></style>
<style>
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
.trip-cost-tour {
  z-index: 500;
}
.greyed-out {
  background-color: #D1D2D4;
  min-height: 100%;
}
.map-container {
    position: relative;
    width: 100%;
    height: 65vh;
}
@media screen and (max-width: 400px) {
  .appCtrlButtons {
    display: none
  }
}
</style>
