<template>
  <div>
    <b-button v-b-toggle.cost-estimation-collapse>
      <b-icon-calculator-fill />
      <span class="pl-3">R {{ totalTripCost }} </span>
      <b-icon-arrow-bar-down />
    </b-button>
    <b-collapse id="cost-estimation-collapse">
      <b-card class="mt-3">
        <validation-observer>
          <b-form autocomplete="off">
            <validated-form-group-field
              :dispatchLabelInputEvent="false"
              fixedLabel
              label="I'll be driving"
              rules="required"
            >
              <template #form-element>
                <b-form-select
                  name="vehicleType"
                  :options="VehicleTypes"
                  v-model="selectedVehicleType"
                />
              </template>
            </validated-form-group-field>
            <validated-form-group-field
              :dispatchLabelInputEvent="false"
              fixedLabel
              label="Fuel type"
              rules="required|min_value:0"
            >
              <template #form-element>
                <b-form-select
                  name="fuelType"
                  :options="fuelPricesList"
                  v-model="selectedFuelType"
                >
                <template #first>
                  <b-form-select-option
                    disabled
                    selected
                    :value="null"
                  >
                    --- Select a Fuel Type ---
                  </b-form-select-option>
                </template>
                </b-form-select>
              </template>
            </validated-form-group-field>
            <validated-form-group-field
              fixedLabel
              rules="required|min_value:0.01"
            >
              <template #form-element>
                <b-form-input
                  name="fuelPricePerLitre"
                  placeholder="Fuel price per litre"
                  step="0.01"
                  type="number"
                  v-model="selectedFuelPrice"
                />
              </template>
            </validated-form-group-field>
            <validated-form-group-field
              fixedLabel
              rules="required|min_value:0.01"
            >
              <template #form-element>
                <b-form-input
                  name="kilometresPerLitre"
                  placeholder="Kilometres per Litre"
                  type="number"
                  v-model="kilometresPerLitre"
                />
              </template>
            </validated-form-group-field>
          </b-form>
        </validation-observer>
        <div class="row">
          <cost-break-down
            @total-trip-cost-changed="totalTripCostChanged"
            class="px-3"
          />
        </div>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
} from 'vuex';

import { extend, ValidationObserver } from 'vee-validate';

import { min_value, numeric, required } from 'vee-validate/dist/rules';

import {
  BButton,
  BCard,
  BCollapse,
  BIconCalculatorFill,
  BIconArrowBarDown,
  BForm,
  BFormInput,
  BFormSelect,
} from 'bootstrap-vue';

import ValidatedFormGroupField from '@/components/ValidatedFormGroupFieldTemplate.vue';

import Constants from '@/store/storeConstants';
import CostBreakDown from './BreakDown.vue';

const {
  GETTERS,
  FuelPricesStore: FPS,
  JourneyStore: JS,
} = Constants;

extend('numeric', {
  ...numeric,
  message: 'This field must be numeric.',
});
extend('min_value', {
  /* eslint camelcase: ["error", { "allow": ["min_value"] }] */
  ...min_value,
  message: 'Value must be greater than 0',
});
extend('required', {
  ...required,
  message: 'This field is required',
});

export default {
  components: {
    BButton,
    BCard,
    BCollapse,
    BForm,
    BFormInput,
    BFormSelect,
    BIconCalculatorFill,
    BIconArrowBarDown,
    CostBreakDown,
    ValidatedFormGroupField,
    ValidationObserver,
  },
  computed: {
    ...mapGetters({
      FuelPricePerLitre: `${JS.Name}/${JS.Getters.FuelPricePerLitre}`,
      FuelPrices: `${FPS.Name}/${GETTERS.Data}`,
      SelectedFuelPrice: `${FPS.Name}/${FPS.Getters.SelectedFuelPrice}`,
      SelectedFuelType: `${FPS.Name}/${FPS.Getters.SelectedFuelType}`,
      KilometresPerLitre: `${JS.Name}/${JS.Getters.KilometresPerLitre}`,
      SelectedVehicleType: `${JS.Name}/${JS.Getters.SelectedVehicleType}`,
      VehicleTypes: `${JS.Name}/${JS.Getters.VehicleTypes}`,
    }),
    fuelPricesList() {
      return (this.FuelPrices || [])
        .map((f) => ({
          text: `${f.type.description} (${f.region.description})`,
          value: f.price,
        }));
    },
    fuelPricePerLitre: {
      get() {
        return this.FuelPricePerLitre;
      },
      set(value) {
        this.SetFuelPricePerLitre(parseFloat(value));
      },
    },
    kilometresPerLitre: {
      get() {
        return this.KilometresPerLitre;
      },
      set(value) {
        this.SetKilometresPerLitre(parseFloat(value));
      },
    },
    selectedVehicleType: {
      get() {
        return this.SelectedVehicleType;
      },
      set(selected) {
        const { value } = this.VehicleTypes.find((type) => selected === type.value);
        this.SetSelectedVehicleType(value);
      },
    },
    selectedFuelPrice: {
      get() {
        return this.SelectedFuelPrice;
      },
      set(selected) {
        this.SetSelectedFuelPrice(parseFloat(selected));
      },
    },
    selectedFuelType: {
      get() {
        return this.SelectedFuelType;
      },
      set(selected) {
        const { value } = this.fuelPricesList.find((price) => price.value === selected);
        this.SetSelectedFuelType(parseFloat(value));
        this.SetSelectedFuelPrice(parseFloat(value));
      },
    },
  },
  data() {
    return {
      totalTripCost: 0,
    };
  },
  methods: {
    ...mapMutations({
      SetFuelPricePerLitre: `${JS.Name}/${JS.Mutations.SetFuelPricePerLitre}`,
      SetKilometresPerLitre: `${JS.Name}/${JS.Mutations.SetKilometresPerLitre}`,
      SetSelectedFuelPrice: `${FPS.Name}/${FPS.Mutations.SetSelectedFuelPrice}`,
      SetSelectedFuelType: `${FPS.Name}/${FPS.Mutations.SetSelectedFuelType}`,
      SetSelectedVehicleType: `${JS.Name}/${JS.Mutations.SetSelectedVehicleType}`,
    }),
    setDefaulFuelPrice() {
      if (!this.FuelPrices || !this.FuelPrices.length) {
        return;
      }
      const { price } = this.FuelPrices[0];
      this.SetSelectedFuelType(parseFloat(price));
      this.SetSelectedFuelPrice(parseFloat(price));
    },
    totalTripCostChanged(newCost) {
      this.totalTripCost = newCost;
    },
  },
  async mounted() {
    this.setDefaulFuelPrice();
    this.SetFuelPricePerLitre(this.SelectedFuelType);
  },
  name: 'CostEstimationCalculator',
  watch: {
    SelectedFuelType(newV) {
      this.SetFuelPricePerLitre(newV);
    },
  },
};
</script>

<style>

</style>
