<template>
  <b-form-group v-slot="{ ariaDescribedby }">
    <b-form-radio-group
      :aria-describedby="ariaDescribedby"
      v-model="selected"
      buttons
    >
      <template>
        <b-form-radio
          :disabled="isDisabled"
          :class="option.className"
          :value="option.value"
          :key="option.text"
          v-for="option in TravelOptions"
        >
          {{ option.text }}
        </b-form-radio>
      </template>
    </b-form-radio-group>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormRadio, BFormRadioGroup } from 'bootstrap-vue';

import { mapGetters, mapMutations } from 'vuex';

import Constants from '@/store/storeConstants';

const { JourneyStore: JS } = Constants;

export default {
  components: {
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
  },
  computed: {
    ...mapGetters({
      SelectedTravelOption: `${JS.Name}/${JS.Getters.SelectedTravelOption}`,
      TravelOptions: `${JS.Name}/${JS.Getters.TravelOptions}`,
    }),
    selected: {
      get() {
        return this.SelectedTravelOption;
      },
      set(selected) {
        const option = this.TravelOptions.find(
          (type) => selected === type.value,
        ).value;
        this.SetSelectedTravelOption(option);
      },
    },
  },
  methods: {
    ...mapMutations({
      SetSelectedTravelOption: `${JS.Name}/${JS.Mutations.SetSelectedTravelOption}`,
    }),
  },
  name: 'TravelOption',
  props: {
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style>
.quickest-travel {
  background-color: #2D8FA8 !important;
  border-color: #2D8FA8 !important;
}
.shortest-travel {
  background-color: #A8509F !important;
  border-color: #A8509F !important;
}
.walking {
  background-color: #003A63 !important;
  border-color: #003A63 !important;
}
</style>
