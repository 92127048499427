<template>
  <div
    class="map-container"
    :id="leafletContainerId"
  >
    Loading Map...
  </div>
</template>

<script>

import {
  mapGetters,
  mapMutations,
} from 'vuex';

import 'leaflet/dist/leaflet.css';
import L from 'leaflet/dist/leaflet';

import Constants from '@/store/storeConstants';

const destinationIconUrl = require('@/assets/icons/destination.png');
const originIconUrl = require('@/assets/icons/origin.png');

const eTollIconUrl = require('@/assets/icons/etoll1_35x35.png');
const tollIconUrl = require('@/assets/icons/toll_35x35.png');

const {
  GETTERS,
  AddressSearchStore: ASS,
  JourneyStore: JS,
} = Constants;
export default {
  computed: {
    ...mapGetters({
      RoutingData: `${JS.Name}/${GETTERS.Data}`,
      DetailedDestinationResults: `${ASS.Name}/${ASS.Getters.DetailedDestinationResults}`,
      DetailedOriginResults: `${ASS.Name}/${ASS.Getters.DetailedOriginResults}`,
      SelectedTravelOption: `${JS.Name}/${JS.Getters.SelectedTravelOption}`,
      SelectedTollGateInfo: `${JS.Name}/${JS.Getters.SelectedTollGateInfo}`,
      TravelOptionCoordinate: `${JS.Name}/${JS.Getters.TravelOptionCoordinate}`,
      TravelOptionTollGates: `${JS.Name}/${JS.Getters.TravelOptionTollGates}`,
    }),
    center: () => [-28.4792625, 24.6727135],
    countryBoundingBox: () => [[-34.8191663551, 16.3449768409], [-22.0913127581, 32.830120477]],
    leafletContainerId: () => 'leaflet-container',
    selectedBaseType: () => 'vector-grey',
  },
  data() {
    return {
      agMapLoaded: false,
      leafletMap: null,
      latLngBounds: null,
      isTollGateInfoPopOpen: false,
      tollGateInfoPop: null,
      routeLayer: null,
      selectedTravelOptionRouteLayer: null,
      tollGateLayer: null,
    };
  },
  methods: {
    ...mapMutations({
      SetSelectedTollGateInfo: `${JS.Name}/${JS.Mutations.SetSelectedTollGateInfo}`,
    }),
    checkIfAgMapLoaded() {
      this.agMapLoaded = !!window.AfriGIS;
      if (this.agMapLoaded) {
        return;
      }
      setTimeout(() => {
        this.checkIfAgMapLoaded();
      }, 500);
    },
    clearRouteLayer() {
      if (this.routeLayer) {
        this.routeLayer.clearLayers();
      }
    },
    clearTollGateLayer() {
      if (this.tollGateLayer) {
        this.tollGateLayer.clearLayers();
      }
    },
    closeTollGateInfo() {
      if (this.isTollGateInfoPopOpen) {
        this.leafletMap.closePopup();
      }
    },
    clearSelectedTravelOptionRouteLayer() {
      if (this.selectedTravelOptionRouteLayer) {
        this.selectedTravelOptionRouteLayer.clearLayers();
      }
    },
    getRoutePolyline(newV, color, isStroked) {
      const weight = isStroked ? 12 : 5;
      return L.polyline(newV, {
        color,
        opacity: 0.5,
        smoothFactor: 1,
        weight,
      }).addTo(this.leafletMap);
    },
    drawMarker(addressDetails, iconUrl, marker) {
      const popupOptions = {
        autoPanPaddingTopLeft: L.point(0, 70),
        offset: [1, -20],
      };
      const coordinate = addressDetails.location;
      const icon = this.generateIcon(iconUrl);
      return this.generateMarker(marker, coordinate, icon)
        .bindPopup(addressDetails.formatted_address, popupOptions);
    },
    drawTollGateLayer() {
      const { Records } = this.TravelOptionTollGates;
      (Records ?? []).forEach((record) => {
        const {
          AG_ID, LATITUDE, LONGITUDE, TYPE,
        } = record;
        const icon = this.getTollIcon(TYPE);

        const toll = L.marker(L.latLng([LATITUDE, LONGITUDE]), {
          icon,
        });
        toll.on('click', (e) => {
          const tollInfo = Records.find((r) => r.AG_ID === AG_ID);
          this.SetSelectedTollGateInfo(tollInfo);
          this.openTollGateInfoPop(e);
        });
        toll.addTo(this.tollGateLayer);
      });
    },
    generateIcon(iconUrl) {
      return L.icon({
        iconAnchor: [18, 32],
        iconSize: [32, 32],
        iconUrl,
        shadowUrl: null,
      });
    },
    generateRouteColor(optimizationParam) {
      if (optimizationParam === 'TRAVELTIME') {
        return '#2D8FA8';
      }
      if (optimizationParam === 'LENGTH') {
        return '#A8509F';
      }
      return '#003A63';
    },
    generateMarker(marker, coordinate, icon) {
      if (marker) {
        this.leafletMap.removeLayer(marker);
      }
      return L.marker(L.latLng([coordinate.lat, coordinate.lng]), {
        icon,
        draggable: false,
      }).addTo(this.leafletMap);
    },
    getTollIcon(type) {
      if (type === 'E-TOLL') {
        return this.generateIcon(eTollIconUrl);
      }
      return this.generateIcon(tollIconUrl);
    },
    initMap() {
      if (this.leafletMap) {
        return;
      }
      this.leafletMap = window.AfriGIS.map(this.leafletContainerId, {
        center: L.latLng(this.center),
        authkey: process.env.VUE_APP_AG_LEAFLET_API_KEY,
        mapType: this.selectedBaseType,
        maxBounds: L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180)),
        maxBoundViscosity: 0,
        maxZoom: 18,
        minZoom: 3,
        zoom: 5,
        zoomControl: true,
      });
      this.routeLayer = L.featureGroup();
      this.routeLayer.addTo(this.leafletMap);

      this.selectedTravelOptionRouteLayer = L.featureGroup();
      this.selectedTravelOptionRouteLayer.addTo(this.leafletMap);

      this.tollGateLayer = L.featureGroup();
      this.tollGateLayer.addTo(this.leafletMap);

      this.leafletMap.fitBounds(this.countryBoundingBox);
      this.leafletMap.zoomControl.setPosition('bottomright');
      this.iniTollGateInfoPop();
    },
    iniTollGateInfoPop() {
      this.tollGateInfoPop = L.popup(
        {
          autoPanPaddingTopLeft: L.point(0, 70),
          closeButton: true,
          closeOnClick: false,
          minWidth: 500,
        },
      );
      const tempParent = document.createElement('div');
      const content = document.querySelector(`#${this.popupTemplateId}`);
      if (content) {
        while (content.childNodes.length) {
          tempParent.appendChild(content.firstChild);
        }
        this.tollGateInfoPop.setContent(tempParent);
      }
      this.leafletMap
        .on('popupopen', () => {
          this.isTollGateInfoPopOpen = true;
        })
        .on('popupclose', () => {
          this.isTollGateInfoPopOpen = false;
        });
    },
    openTollGateInfoPop(e) {
      this.tollGateInfoPop
        .setLatLng(e.target.getLatLng())
        .openOn(this.leafletMap);
    },
    removeMarker(marker) {
      if (marker) {
        this.leafletMap.removeLayer(marker);
      }
    },
  },
  mounted() {
    this.checkIfAgMapLoaded();
  },
  name: 'LeafletMap',
  props: {
    popupTemplateId: {
      required: true,
      type: String,
    },
  },
  watch: {
    agMapLoaded(newV) {
      if (!newV) {
        return;
      }
      this.initMap();
    },
    DetailedDestinationResults(newV) {
      if (!newV) {
        this.closeTollGateInfo();
        this.removeMarker(this.destinationMarker);
        this.destinationMarker = null;
        return;
      }
      this.closeTollGateInfo();
      this.clearRouteLayer();
      this.clearSelectedTravelOptionRouteLayer();
      this.clearTollGateLayer();
      this.destinationMarker = this.drawMarker(newV, destinationIconUrl, this.destinationMarker);
      if (!this.latLngBounds) {
        this.latLngBounds = new L.LatLngBounds();
      }
      this.latLngBounds.extend(this.destinationMarker.getLatLng());
      this.leafletMap.fitBounds(this.latLngBounds);
    },
    DetailedOriginResults(newV) {
      if (!newV) {
        this.closeTollGateInfo();
        this.removeMarker(this.originMarker);
        this.originMarker = null;
        return;
      }
      this.closeTollGateInfo();
      this.clearRouteLayer();
      this.clearSelectedTravelOptionRouteLayer();
      this.clearTollGateLayer();
      this.originMarker = this.drawMarker(newV, originIconUrl, this.originMarker);
      if (!this.latLngBounds) {
        this.latLngBounds = new L.LatLngBounds();
      }
      this.latLngBounds.extend(this.originMarker.getLatLng());
      this.leafletMap.fitBounds(this.latLngBounds);
    },
    RoutingData(newV) {
      this.closeTollGateInfo();
      this.SetSelectedTollGateInfo(null);
      this.clearRouteLayer();
      this.clearSelectedTravelOptionRouteLayer();
      this.clearTollGateLayer();
      if (!newV || !newV.length) {
        return;
      }
      newV.forEach((data) => {
        const { coordinates, optimizationParam } = data;
        const color = this.generateRouteColor(optimizationParam);
        const polyline = this.getRoutePolyline(coordinates, color, false);
        polyline.addTo(this.routeLayer);
      });
      const routelayerCenter = this.routeLayer.getBounds().getCenter();
      this.latLngBounds.extend(routelayerCenter);
      this.leafletMap.fitBounds(this.latLngBounds);

      const color = this.generateRouteColor(this.SelectedTravelOption);
      const polyline = this.getRoutePolyline(this.TravelOptionCoordinate, color, true);
      polyline.addTo(this.selectedTravelOptionRouteLayer);
      this.drawTollGateLayer();
    },
    SelectedTravelOption(newV) {
      this.closeTollGateInfo();
      this.SetSelectedTollGateInfo(null);
      this.clearSelectedTravelOptionRouteLayer();
      this.clearTollGateLayer();
      if (!newV) {
        return;
      }
      const color = this.generateRouteColor(newV);
      const polyline = this.getRoutePolyline(this.TravelOptionCoordinate, color, true);
      polyline.addTo(this.selectedTravelOptionRouteLayer);
      this.drawTollGateLayer();
    },
    SelectedTollGateInfo(newV) {
      if (!newV) {
        this.isTollGateInfoPopOpen = false;
      }
    },
    isTollGateInfoPopOpen(isOpen) {
      if (!isOpen) {
        this.SetSelectedTollGateInfo(null);
      }
    },
  },
};
</script>

<style>
.leaflet-bar a{
    background-color: var(--primary) !important;
    color: #fff !important;
  }
</style>
