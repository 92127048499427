import { createAwsAxiosInstance, fetchTripCosts } from '@afrigis/aws-navigation-routes';
import { GettersUtility, ModuleUtilities, MutationsUtility } from '@afrigis/vuex-utilities';

import Constants from '@/store/storeConstants';

const {
  ACTIONS,
  GETTERS,
  MUTATIONS,
  STATE_VARS,
  AwsAxiosStore: AAS,
  JourneyStore: JS,
} = Constants;

const initialState = () => ({
  [STATE_VARS.DATA]: null,
  [STATE_VARS.IS_LOADING]: false,
  [JS.StateVars.FuelPricePerLitre]: 0,
  [JS.StateVars.KilometresPerLitre]: 10,
  [JS.StateVars.SelectedTollGateInfo]: null,
  [JS.StateVars.SelectedTravelOption]: 'TRAVELTIME',
  [JS.StateVars.SelectedVehicleType]: 'CLASS_2',
  [JS.StateVars.TravelOptions]: [
    {
      text: 'Quickest Travel Time',
      value: 'TRAVELTIME',
      className: 'quickest-travel',
    },
    {
      text: 'Shortest Distance',
      value: 'LENGTH',
      className: 'shortest-travel',
    },
    {
      text: 'Walking',
      value: 'WALKING',
      className: 'walking',
    },
  ],
  [JS.StateVars.VehicleTypes]: [
    {
      text: 'Motorcycle',
      value: 'CLASS_1',
    },
    {
      text: 'Light vehicle',
      value: 'CLASS_1',
    },
    {
      text: 'Small heavy vehicle',
      value: 'CLASS_2',
    },
    {
      text: 'Large heavy vehicle',
      value: 'CLASS_3',
    },
    {
      text: 'Extra Large heavy vehicle',
      value: 'CLASS_4',
    },
  ],
});

const STATE_PROPS_TO_EXPOSE = [
  ...Object.values(STATE_VARS),
  ...Object.values(JS.StateVars),
];

const actions = {
  [ACTIONS.LOAD]: async (context, payload) => {
    if (!payload) {
      throw new Error('"payload" Parameter is required.');
    }

    try {
      context.commit(MUTATIONS.SetIsLoading, true);
      const { Token, Key } = context.rootState[AAS.Name];
      const axiosInstance = await createAwsAxiosInstance(Token, Key);
      const { origin, destination } = payload;
      const result = await fetchTripCosts({
        axiosInstance,
        origin,
        destination,
        bufferAgOptions: {
          version: '1.0.0',
        },
      });
      context.commit(MUTATIONS.SetData, result);
    } catch (err) {
      const errorDescription = (err.response && err.response.data)
        ? err.response.data.message
        : err.message;
      throw new Error(errorDescription);
    } finally {
      context.commit(MUTATIONS.SetIsLoading, false);
    }
  },
};

const state = initialState();
const getters = {
  ...GettersUtility.scaffold(state, STATE_PROPS_TO_EXPOSE),
  TravelOptionCoordinate: (stateP) => {
    const data = stateP[GETTERS.Data];
    const selectedTravelOption = stateP[JS.Getters.SelectedTravelOption];
    if (!selectedTravelOption
      || !stateP[GETTERS.Data]
      || !stateP[GETTERS.Data].length) {
      return [];
    }
    return data
      .find((d) => d.optimizationParam === selectedTravelOption)
      ?.coordinates ?? [];
  },
  TravelOptionDirections: (stateP) => {
    const data = stateP[GETTERS.Data];
    const selectedTravelOption = stateP[JS.Getters.SelectedTravelOption];
    if (!selectedTravelOption
      || !stateP[GETTERS.Data]
      || !stateP[GETTERS.Data].length) {
      return [];
    }
    return data
      .find((d) => d.optimizationParam === selectedTravelOption)
      ?.directions ?? [];
  },
  TravelOptionTotalTravelTime: (stateP) => {
    const data = stateP[GETTERS.Data];
    const selectedTravelOption = stateP[JS.Getters.SelectedTravelOption];
    if (!selectedTravelOption
      || !stateP[GETTERS.Data]
      || !stateP[GETTERS.Data].length) {
      return null;
    }
    return data
      .find((d) => d.optimizationParam === selectedTravelOption)
      ?.totalTravelTime ?? null;
  },
  TravelOptionTollGates: (stateP) => {
    const data = stateP[GETTERS.Data];
    const selectedTravelOption = stateP[JS.Getters.SelectedTravelOption];
    if (!selectedTravelOption
      || !stateP[GETTERS.Data]
      || !stateP[GETTERS.Data].length) {
      return [];
    }

    const route = data
      .find((d) => d.optimizationParam === selectedTravelOption);

    return (!route.tollGates?.length)
      ? []
      : route.tollGates[0];
  },
  TravelOptionTotalLength: (stateP) => {
    const data = stateP[GETTERS.Data];
    const selectedTravelOption = stateP[JS.Getters.SelectedTravelOption];
    if (!selectedTravelOption
      || !stateP[GETTERS.Data]
      || !stateP[GETTERS.Data].length) {
      return null;
    }
    return data
      .find((d) => d.optimizationParam === selectedTravelOption)
      ?.totalLength ?? null;
  },
};

const mutations = {
  ...MutationsUtility.scaffold(state, STATE_PROPS_TO_EXPOSE),
  [MUTATIONS.Reset]: ModuleUtilities.ResetToInitial(initialState),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
