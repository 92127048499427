export default {
  ACTIONS: {
    LOAD: 'Load',
  },

  GETTERS: {
    Data: 'Data',
    IsLoading: 'IsLoading',
  },

  MUTATIONS: {
    Reset: 'Reset',
    SetData: 'SetData',
    SetIsLoading: 'SetIsLoading',
  },

  STATE_VARS: {
    DATA: 'Data',
    IS_LOADING: 'IsLoading',
  },

  AddressSearchStore: {
    Actions: {
      AddressSearch: 'AddressSearch',
      DoAutocomplete: 'DoAutocomplete',
      DoSearch: 'DoSearch',
      DoSearchDetails: 'DoSearchDetails',
      SearchLastEnteredText: 'SearchLastEnteredText',
    },
    Getters: {
      AxiosInstance: 'AxiosInstance',
      AxiosAutocompleteSource: 'AxiosAutocomplete',
      DetailedOriginResults: 'DetailedOriginResults',
      DetailedDestinationResults: 'DetailedDestinationResults',
      LastEnteredText: 'LastEnteredText',
      LoadingDestinationSuggestions: 'LoadingDestinationSuggestions',
      LoadingOriginSuggestions: 'LoadingOriginSuggestions',
      SearchErrorMessage: 'SearchErrorMessage',
    },
    Mutations: {
      CancelAxiosAutocompleteSource: 'CancelAxiosAutocompleteSource',
      SetAxiosAutocompleteSource: 'SetAxiosAutocompleteSource',
      SetAxiosInstance: 'SetAxiosInstance',
      SetDetailedOriginResults: 'SetDetailedOriginResults',
      SetDetailedDestinationResults: 'SetDetailedDestinationResults',
      SetLastEnteredText: 'SetLastEnteredText',
      SetLoadingDestinationSuggestions: 'SetLoadingDestinationSuggestions',
      SetLoadingOriginSuggestions: 'SetLoadingOriginSuggestions',
      SetSearchErrorMessage: 'SetSearchErrorMessage',
    },
    Name: 'addressSearchStore',
    StateVars: {
      AxiosInstance: 'axiosInstance',
      AxiosAutocompleteSource: 'axiosAutocompleteSource',
      DetailedOriginResults: 'detailedOriginResults',
      DetailedDestinationResults: 'detailedDestinationResults',
      LastEnteredText: 'lastEnteredText',
      LoadingDestinationSuggestions: 'loadingDestinationSuggestions',
      LoadingOriginSuggestions: 'loadingOriginSuggestions',
      SearchErrorMessage: 'searchErrorMessage',
    },
  },

  AwsAxiosStore: {
    Actions: {
      LoadAwsAuthentication: 'LoadAwsAuthentication',
    },
    Getters: {
      Instance: 'Instance',
      Key: 'Key',
      Token: 'Token',
      TokenRefreshStrategy: 'TokenRefreshStrategy',
    },
    Mutations: {
      SetToken: 'SetToken',
      SetKey: 'SetKey',
      SetTokenRefreshStrategy: 'SetTokenRefreshStrategy',
    },
    Name: 'awsAxiosStore',
    StateVars: {
      Instance: 'Instance',
      Key: 'Key',
      Token: 'Token',
      TokenRefreshStrategy: 'TokenRefreshStrategy',
    },
  },

  FuelPricesStore: {
    Getters: {
      SelectedFuelType: 'SelectedFuelType',
      SelectedFuelPrice: 'SelectedFuelPrice',
    },
    Mutations: {
      SetSelectedFuelType: 'SetSelectedFuelType',
      SetSelectedFuelPrice: 'SetSelectedFuelPrice',
    },
    Name: 'fuelPricesStore',
    StateVars: {
      SelectedFuelType: 'SelectedFuelType',
      SelectedFuelPrice: 'selectedFuelPrice',
    },
  },

  JourneyStore: {
    Getters: {
      FuelPricePerLitre: 'FuelPricePerLitre',
      KilometresPerLitre: 'KilometresPerLitre',
      SelectedTravelOption: 'SelectedTravelOption',
      SelectedVehicleType: 'SelectedVehicleType',
      SelectedTollGateInfo: 'SelectedTollGateInfo',
      TravelOptions: 'TravelOptions',
      TravelOptionCoordinate: 'TravelOptionCoordinate',
      TravelOptionDirections: 'TravelOptionDirections',
      TravelOptionTotalTravelTime: 'TravelOptionTotalTravelTime',
      TravelOptionTollGates: 'TravelOptionTollGates',
      TravelOptionTotalLength: 'TravelOptionTotalLength',
      VehicleTypes: 'VehicleTypes',
    },
    Mutations: {
      SetFuelPricePerLitre: 'SetFuelPricePerLitre',
      SetKilometresPerLitre: 'SetKilometresPerLitre',
      SetSelectedTravelOption: 'SetSelectedTravelOption',
      SetSelectedTollGateInfo: 'SetSelectedTollGateInfo',
      SetSelectedVehicleType: 'SetSelectedVehicleType',
    },
    Name: 'journeyStore',
    StateVars: {
      FuelPricePerLitre: 'FuelPricePerLitre',
      KilometresPerLitre: 'KilometresPerLitre',
      SelectedTravelOption: 'SelectedTravelOption',
      SelectedTollGateInfo: 'SelectedTollGateInfo',
      SelectedVehicleType: 'SelectedVehicleType',
      TravelOptions: 'TravelOptions',
      VehicleTypes: 'VehicleTypes',
    },
  },
};
