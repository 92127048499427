<template>
  <b-form autocomplete="off">
    <b-form-group>
      <float-label fixed>
        <origin-address
          class="overlay origin-input"
          id="originAddress"
          placeholder="Origin Address"
          :options="Suggestions"
          :processing="LoadingOriginSuggestions"
          :text="searchOriginText"
          @cleared="clearOriginAddress"
          @onChosen="onOriginChosen"
          @onInput="autocompleteOrigin"
          @onSearch="searchOrigin"
        />
      </float-label>
    </b-form-group>
    <b-form-group
      class="mt-5 pt-2"
    >
      <float-label fixed>
        <destination-address
          class="overlay"
          id="destinationAutocomplete"
          placeholder="Destination Address"
          :options="Suggestions"
          :processing="LoadingDestinationSuggestions"
          :text="searchDestinationText"
          @cleared="clearDestinationAddress"
          @onChosen="onDestinationChosen"
          @onInput="autocompleteDestination"
          @onSearch="searchDestination"
        />
      </float-label>
    </b-form-group>
    <b-row v-if="CanGenerateRoute">
      <b-col md="4" class="py-4 text-left">
        <b-button
          @click="getRoute"
          :disabled="IsLoadingRoutes || isDisabled"
          class="mt-3"
        >
          Get Route
          <b-spinner small v-if="IsLoadingRoutes" />
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BRow,
  BSpinner,
} from 'bootstrap-vue';

import addressSearch from '@/mixins/addressSearch';

import Constants from '@/store/storeConstants';

import AgAutocomplete from '@afrigis/afrigis-vue-autocomplete';

const {
  ACTIONS,
  MUTATIONS,
  GETTERS,
  AddressSearchStore: ASS,
  JourneyStore: JS,
} = Constants;

export default {
  components: {
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BRow,
    DestinationAddress: AgAutocomplete,
    OriginAddress: AgAutocomplete,
    BSpinner,
  },
  computed: {
    ...mapGetters({
      IsLoadingRoutes: `${JS.Name}/${GETTERS.IsLoading}`,
      DetailedDestinationResults: `${ASS.Name}/${ASS.Getters.DetailedDestinationResults}`,
      DetailedOriginResults: `${ASS.Name}/${ASS.Getters.DetailedOriginResults}`,
    }),
    CanGenerateRoute() {
      return !!this.DetailedOriginResults && !!this.DetailedDestinationResults;
    },
  },
  data() {
    return {
      searchOriginText: '',
      searchDestinationText: '',
      seoidOrigin: null,
      seoidDestination: null,
    };
  },
  methods: {
    ...mapActions({
      GetRoutes: `${JS.Name}/${ACTIONS.LOAD}`,
    }),
    ...mapMutations({
      SetRouteData: `${JS.Name}/${MUTATIONS.SetData}`,
    }),
    autocompleteDestination(searchText) {
      this.autocomplete('destination', searchText);
    },
    autocompleteOrigin(searchText) {
      this.autocomplete('origin', searchText);
    },
    clearDestinationAddress() {
      this.searchDestinationText = '';
      this.seoidDestination = null;
      this.SetDetailedDestinationResults(null);
      this.SetSuggestions(null);
    },
    clearOriginAddress() {
      this.searchOriginText = '';
      this.seoidOrigin = null;
      this.SetDetailedOriginResults(null);
      this.SetSuggestions(null);
    },
    getRoute() {
      const payload = {
        origin: {
          latitude: this.DetailedOriginResults.location.lat,
          longitude: this.DetailedOriginResults.location.lng,
        },
        destination: {
          latitude: this.DetailedDestinationResults.location.lat,
          longitude: this.DetailedDestinationResults.location.lng,
        },
      };
      this.GetRoutes(payload);
    },
    onDestinationChosen(data) {
      this.SetRouteData(null);
      this.clearDestinationAddress();
      this.searchDestinationText = data.description;
      this.seoidDestination = data.seoid;
      this.DoSearchDetails({
        data,
        direction: 'destination',
      });
    },
    onOriginChosen(data) {
      this.SetRouteData(null);
      this.clearOriginAddress();
      this.searchOriginText = data.description;
      this.seoidOrigin = data.seoid;
      this.DoSearchDetails({
        data,
        direction: 'origin',
      });
    },
    searchDestination(searchText) {
      this.DoSearch({
        direction: 'destination',
        searchText,
      });
    },
    searchOrigin(searchText) {
      this.DoSearch({
        direction: 'origin',
        searchText,
      });
    },
    autocomplete(direction, searchText) {
      if (searchText.length < 3) {
        return;
      }
      this.SetLastEnteredText(searchText);
      if (this.autocompleteCharacterTimer) {
        clearTimeout(this.autocompleteCharacterTimer);
      }
      this.autocompleteCharacterTimer = setTimeout(() => {
        this.searchError = null;
        this.DoAutocomplete({ direction, searchText })
          .catch(() => {
            this.searchError = 'Could not Autocomplete';
          });
      }, 300);
    },
  },
  mixins: [addressSearch],
  name: 'AddressSearch',
  props: {
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.overlay {
  position: absolute;
  width: 100%;
  z-index: 900;
}
.origin-input {
  z-index: 1000;
}

</style>
