<template>
  <b-form-group>
    <validation-provider
      v-slot="{ errors }"
      :rules="rules"
    >
      <float-label
        :fixed="fixedLabel"
        :label="label"
      >
        <slot name="form-element">
          No Field Specified
        </slot>
      </float-label>
      <ag-validation-error-label :error="errors[0]" />
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import {
  BFormGroup,
} from 'bootstrap-vue';

import AgValidationErrorLabel from '@afrigis/vue-validation-error-label';

export default {
  components: {
    AgValidationErrorLabel,
    BFormGroup,
    ValidationProvider,
  },
  name: 'FormGroupFieldTemplate',
  props: {
    dispatchLabelInputEvent: {
      default: true,
      required: false,
      type: Boolean,
    },
    fixedLabel: {
      default: false,
      required: false,
      type: Boolean,
    },
    label: {
      default: null,
      required: false,
      type: String,
    },
    rules: {
      default: '',
      required: false,
      type: String,
    },
  },
};
</script>
