<template>
  <b-container
    fluid
    :id="templateId"
    v-show="false"
  >
    <h4
      class="text-center"
      :class="tableClasses"
      v-show="!!SelectedTollGateInfo"
    >
      {{ tollName }}
    </h4>
    <b-row v-show="!!SelectedTollGateInfo">
      <table class="table" style="border-spacing: 0" cellspacing="0">
        <tbody>
          <tr :class="tableClasses">
            <th>Type</th>
            <th>Class 1</th>
            <th>Class 2</th>
            <th>Class 3</th>
            <th>Class 4</th>
          </tr>
          <tr>
            <td :class="tableClasses">*Amount in Rands</td>
            <td :key="`icons-${index}`" v-for="(iconClass, index) in tollIcons">
              <img
                alt=""
                :key="`icons-class-${index}`"
                v-for="(icon, index) in iconClass"
                :src="icon"
              />
            </td>
          </tr>
          <tr>
            <td class="text-primary">{{ tollType }}</td>
            <td
              class="text-primary"
              :key="`cost-${index}`"
              v-for="(cost, index) in costs"
            >
              R {{ cost }}
            </td>
          </tr>
        </tbody>
      </table>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import {
  BRow,
  BContainer,
} from 'bootstrap-vue';

import Constants from '@/store/storeConstants';

import tollIconsUtil from '@/utils/tollIcons';

const { tollIconsUrl } = tollIconsUtil;

const {
  JourneyStore: JS,
} = Constants;

export default {
  components: {
    BRow,
    BContainer,
  },
  computed: {
    ...mapGetters({
      SelectedTollGateInfo: `${JS.Name}/${JS.Getters.SelectedTollGateInfo}`,
      TravelOptionTollGates: `${JS.Name}/${JS.Getters.TravelOptionTollGates}`,
    }),
    costs() {
      const info = this.SelectedTollGateInfo;
      if (!info) {
        return [];
      }
      return [
        parseFloat(info.CLASS_1).toFixed(2),
        parseFloat(info.CLASS_2).toFixed(2),
        parseFloat(info.CLASS_3).toFixed(2),
        parseFloat(info.CLASS_4).toFixed(2),
      ];
    },
    tableClasses() {
      return this.tollType === 'E-TOLL' ? 'text-orange' : 'text-secondary';
    },
    tollName() {
      /**
       * F_TOLL_PLA seems to always be defined,
       * the unnamed Toll text is a fall back becasue i'm paranoid, arent we all?
       */
      return this.SelectedTollGateInfo?.F_TOLL_PLA ?? 'Unnamed Toll';
    },
    tollIcons() {
      return tollIconsUrl(this.tollType);
    },
    tollType() {
      return this.SelectedTollGateInfo?.TYPE;
    },
  },
  methods: {
    ...mapMutations({
      SetSelectedTollGateInfo: `${JS.Name}/${JS.Mutations.SetSelectedTollGateInfo}`,
    }),
  },
  props: {
    templateId: {
      required: true,
      type: String,
    },
  },
};
</script>

<style scoped>
.text-orange {
  color: var(--orange);
}
</style>
