<template>
  <div>
    <div class="h4">Cost breakdown</div>
    <b-card class="card w-100">
      <dl class="row no-gutters">
        <dt :class="definitionTermClasses(0)">
          e-Toll cost (excl discount):
        </dt>
        <dd :class="definitionValueClasses(0)">
          R {{ TotalCostOfETolls.toFixed(2) }}
        </dd>
        <dt :class="definitionTermClasses(1)">
          Regular Toll Fees:
        </dt>
        <dd :class="definitionValueClasses(1)">
          R {{ TotalCostOfMainline.toFixed(2) }}
        </dd>
        <dt :class="definitionTermClasses(2)">
          Petrol cost*:
        </dt>
        <dd :class="definitionValueClasses(2)">
          R {{ TotalFuelCost.toFixed(2) }}
        </dd><dt :class="definitionTermClasses(3)">
          Total cost for trip:
        </dt>
        <dd :class="definitionValueClasses(3)">
          R {{ TotalCostForTrip }}
        </dd>
      </dl>
      <p> *using latest inland fuel price </p>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue';

import Constants from '@/store/storeConstants';
import { mapGetters } from 'vuex';

const {
  FuelPricesStore: FPS,
  JourneyStore: JS,
} = Constants;

export default {
  components: {
    BCard,
  },
  computed: {
    ...mapGetters({
      KilometresPerLitre: `${JS.Name}/${JS.Getters.KilometresPerLitre}`,
      SelectedFuelPrice: `${FPS.Name}/${FPS.Getters.SelectedFuelPrice}`,
      SelectedVehicleType: `${JS.Name}/${JS.Getters.SelectedVehicleType}`,
      TravelOptionTotalLength: `${JS.Name}/${JS.Getters.TravelOptionTotalLength}`,
      TravelOptionTollGates: `${JS.Name}/${JS.Getters.TravelOptionTollGates}`,
    }),
    compoundPropertiesWather() {
      // `.join()` because we don't care about the return value.
      // We only using to check if either one of the properties is changed
      return [
        this.SelectedVehicleType,
        this.SelectedFuelPrice,
        this.KilometresPerLitre,
      ].join();
    },
    TotalFuelCost() {
      return ((parseFloat(this.SelectedFuelPrice)
      / parseFloat(this.KilometresPerLitre))
      * parseFloat(this.TravelOptionTotalLength));
    },
    TotalCostForTrip() {
      return (parseFloat(this.TotalCostOfETolls)
        + parseFloat(this.TotalCostOfMainline)
        + parseFloat(this.TotalFuelCost)).toFixed(2);
    },
  },
  data() {
    return {
      TotalCostOfETolls: 0.00,
      TotalCostOfMainline: 0.00,
    };
  },
  methods: {
    calculateTotalCostOfETolls(records) {
      let costOfEToll = 0.00;
      (records || [])
        .filter((r) => r.TYPE === 'E-TOLL')
        .forEach((i) => {
          costOfEToll += parseFloat(i[this.SelectedVehicleType]);
        });

      this.TotalCostOfETolls = costOfEToll;
    },
    calculateTotalCostOfMainlineTolls(records) {
      let costOfMainline = 0.00;
      (records || [])
        .filter((r) => r.TYPE === 'MAINLINE')
        .forEach((i) => {
          costOfMainline += parseFloat(i[this.SelectedVehicleType]);
        });

      this.TotalCostOfMainline = costOfMainline;
    },
    calculateTotalCostOfTolls() {
      if (!this.TravelOptionTollGates) {
        return;
      }

      const { Records } = this.TravelOptionTollGates;
      this.calculateTotalCostOfETolls(Records);
      this.calculateTotalCostOfMainlineTolls(Records);
    },
    definitionClasses(index) {
      const arr = ['pt-1', 'pb-1', 'm-0', 'wrap'];
      if (index % 2 === 0) {
        arr.push('bg-light');
      }
      return arr;
    },
    definitionTermClasses(index) {
      const arr = this.definitionClasses(index);
      arr.push('font-weight-bold');
      arr.push('col-6');
      return arr;
    },
    definitionValueClasses(index) {
      const arr = this.definitionClasses(index);
      arr.push('col-6');
      return arr;
    },
  },
  mounted() {
    this.calculateTotalCostOfTolls();
  },
  name: 'CostBreakDown',
  watch: {
    compoundPropertiesWather() {
      this.calculateTotalCostOfTolls();
    },
    TotalCostForTrip: {
      handler(newV) {
        this.$emit('total-trip-cost-changed', newV);
      },
      immediate: true,
    },
  },
};
</script>

<style>

</style>
