import Axios from 'axios';
import { GettersUtility, ModuleUtilities, MutationsUtility } from '@afrigis/vuex-utilities';

import Constants from '@/store/storeConstants';

const {
  GETTERS,
  MUTATIONS,
  AwsAxiosStore: AAS,
  STATE_VARS,
} = Constants;

const ENDPOINT = `${process.env.VUE_APP_ROOT_API}/proxies/search/api/awsauthentication`;

const STATE_PROPS_TO_EXPOSE = [
  ...Object.values(AAS.StateVars),
  STATE_VARS.IS_LOADING,
];
const actions = {
  [AAS.Actions.LoadAwsAuthentication]: async (context) => {
    context.commit(MUTATIONS.SET_IS_LOADING, true);
    context.commit(AAS.Mutations.SetToken, null);
    context.commit(AAS.Mutations.SetKey, null);
    try {
      const { data: { code, message, result } } = await Axios.get(ENDPOINT);
      if (code !== 200) {
        throw new Error(message);
      }
      const { token, key } = result;
      context.commit(AAS.Mutations.SetToken, token);
      context.commit(AAS.Mutations.SetKey, key);

      const refreshTimer = 3600 * 1000 - 60000;
      if (context.state[AAS.StateVars.TokenRefreshStrategy]) {
        clearTimeout(context.state[AAS.StateVars.TokenRefreshStrategy]);
      }
      const refresh = setTimeout(() => {
        context.dispatch(AAS.Actions.LoadAwsAuthentication);
      }, refreshTimer);

      context.commit(AAS.Mutations.SetTokenRefreshStrategy, refresh);
    } catch {
      throw Error('Could not load AWS Axios Instance');
    } finally {
      context.commit(MUTATIONS.SET_IS_LOADING, false);
    }
  },
};

const initialState = () => ({
  [AAS.StateVars.Instance]: Axios.create(),
  [AAS.StateVars.Token]: null,
  [AAS.StateVars.Key]: null,
  [STATE_VARS.IS_LOADING]: false,
  [AAS.StateVars.TokenRefreshStrategy]: null,
});

const state = initialState();

const getters = {
  ...GettersUtility.scaffold(state, STATE_PROPS_TO_EXPOSE),
  ...GettersUtility.scaffold(state, [STATE_VARS.IS_LOADING]),
  [GETTERS.Data]: (stateP) => {
    if (!stateP[AAS.StateVars.Key]
      || !stateP[AAS.StateVars.Token]
      || !stateP[AAS.StateVars.Instance]) {
      return null;
    }
    const axiosInstance = stateP[AAS.StateVars.Instance];
    axiosInstance.interceptors.request
      .use(
        (config) => {
          const authorisationHeader = `Bearer ${stateP[AAS.StateVars.Token]}`;
          const { headers } = config;
          headers.Authorization = authorisationHeader;
          headers.common['x-api-key'] = stateP[AAS.StateVars.Key];
          headers.common['Access-Control-Allow-Origin'] = '*';
          return config;
        },
        (error) => {
          Promise.reject(error);
        },
      );
    return axiosInstance;
  },
};

const mutations = {
  ...MutationsUtility.scaffold(state, STATE_PROPS_TO_EXPOSE),
  [MUTATIONS.Reset]: ModuleUtilities.ResetToInitial(initialState),
};

export default {
  actions,
  getters,
  mutations,
  state,
};
