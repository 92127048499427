import { mapActions, mapGetters, mapMutations } from 'vuex';
import Constants from '@/store/storeConstants';

const {
  GETTERS,
  MUTATIONS,
  AddressSearchStore: ASS,
} = Constants;

export default {
  computed: {
    ...mapGetters({
      Suggestions: `${ASS.Name}/${GETTERS.Data}`,
      LoadingDestinationSuggestions: `${ASS.Name}/${ASS.Getters.LoadingDestinationSuggestions}`,
      LoadingOriginSuggestions: `${ASS.Name}/${ASS.Getters.LoadingOriginSuggestions}`,
      token: 'addressSearchStore/token',
    }),
  },
  data() {
    return {
      autocompleteCharacterTimer: null,
      searchError: null,
    };
  },
  methods: {
    ...mapActions({
      DoAutocomplete: `${ASS.Name}/${ASS.Actions.DoAutocomplete}`,
      DoSearch: `${ASS.Name}/${ASS.Actions.DoSearch}`,
      DoSearchDetails: `${ASS.Name}/${ASS.Actions.DoSearchDetails}`,
      SearchLastEnteredText: `${ASS.Name}/${ASS.Actions.SearchLastEnteredText}`,
    }),
    ...mapMutations({
      SetDetailedDestinationResults: `${ASS.Name}/${ASS.Mutations.SetDetailedDestinationResults}`,
      SetDetailedOriginResults: `${ASS.Name}/${ASS.Mutations.SetDetailedOriginResults}`,
      SetLastEnteredText: `${ASS.Name}/${ASS.Mutations.SetLastEnteredText}`,
      SetSuggestions: `${ASS.Name}/${MUTATIONS.SetData}`,
    }),
  },
};
