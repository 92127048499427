import Axios from 'axios';
import {
  GettersUtility,
  ModuleUtilities,
  MutationsUtility,
} from '@afrigis/vuex-utilities';

import { addressDetails, addressSearch } from '@afrigis/aws-search-services';

import Constants from '@/store/storeConstants';

const {
  MUTATIONS,
  STATE_VARS,
  AddressSearchStore: ASS,
} = Constants;

const initialState = () => ({
  [STATE_VARS.DATA]: null,
  [STATE_VARS.IS_LOADING]: false,
  [ASS.StateVars.AxiosInstance]: null,
  [ASS.StateVars.AxiosAutocompleteSource]: null,
  [ASS.StateVars.DetailedDestinationResults]: null,
  [ASS.StateVars.DetailedOriginResults]: null,
  [ASS.StateVars.LastEnteredText]: null,
  [ASS.StateVars.SearchErrorMessage]: null,
});

const STATE_PROPS_TO_EXPOSE = [
  ...Object.values(STATE_VARS),
  ...Object.values(ASS.StateVars),
];

const state = initialState();

const getters = {
  ...GettersUtility.scaffold(state, STATE_PROPS_TO_EXPOSE),
};

const actions = {
  [ASS.Actions.AddressSearch]: async (context, payload) => {
    if (!context.state[ASS.StateVars.AxiosInstance]) {
      throw new Error('No Axios instance set.');
    }

    if (payload.direction === 'origin') {
      context.commit(ASS.Mutations.SetLoadingOriginSuggestions, true);
    } else {
      context.commit(ASS.Mutations.SetLoadingDestinationSuggestions, true);
    }

    context.commit(MUTATIONS.SetData, null);

    try {
      const { data: { code, message, result } } = await addressSearch(
        context.state[ASS.StateVars.AxiosInstance],
        payload.searchText,
      );
      if (code !== 200) {
        throw new Error(message);
      }

      const suggestions = result
        .map((r) => ({
          seoid: r.seoid,
          description: r.formatted_address,
          latitude: r.location.lat,
          longitude: r.location.lng,
          type: 'address',
        }));
      context.commit(MUTATIONS.SetData, suggestions);
    } catch (error) {
      const errorDescription = (error.response && error.response.data)
        ? error.response.data.message
        : error.message;
      throw new Error(errorDescription);
    } finally {
      if (payload.direction === 'origin') {
        context.commit(ASS.Mutations.SetLoadingOriginSuggestions, false);
      } else {
        context.commit(ASS.Mutations.SetLoadingDestinationSuggestions, false);
      }
    }
  },
  [ASS.Actions.DoAutocomplete]: async (context, payload) => {
    if (!context.state[ASS.StateVars.AxiosInstance]) {
      throw new Error('No Axios instance set.');
    }

    if (payload.direction === 'origin') {
      context.commit(ASS.Mutations.SetLoadingOriginSuggestions, true);
    } else {
      context.commit(ASS.Mutations.SetLoadingDestinationSuggestions, true);
    }

    context.commit(MUTATIONS.SetData, null);
    context.commit(ASS.Mutations.CancelAxiosAutocompleteSource);
    context.commit(ASS.Mutations.SetAxiosAutocompleteSource, Axios.CancelToken.source());

    const autocompleteUrl = 'https://afrigis.services/places-autocomplete/api/v3/autocomplete';
    const encodedSearch = encodeURIComponent(payload.searchText);
    try {
      const { data: { code, result } } = await context.state[ASS.StateVars.AxiosInstance]({
        url: `${autocompleteUrl}?query=${encodedSearch}`,
        cancelToken: context.state[ASS.StateVars.AxiosAutocompleteSource].token,
      });

      if (code !== 200) {
        context.commit(ASS.Mutations.SetSearchErrorMessage, 'Could not autocomplete');
        return;
      }

      const mappedResults = result
        .map((r) => ({
          ...r,
          type: 'address',
        }));
      context.commit(MUTATIONS.SetData, mappedResults);
    } catch (error) {
      const errorDescription = (error.response && error.response.data)
        ? error.response.data.message
        : error.message;
      throw new Error(errorDescription);
    } finally {
      if (payload.direction === 'origin') {
        context.commit(ASS.Mutations.SetLoadingOriginSuggestions, false);
      } else {
        context.commit(ASS.Mutations.SetLoadingDestinationSuggestions, false);
      }
    }
  },
  [ASS.Actions.DoSearch]: async (context, payload) => {
    try {
      await context.dispatch(ASS.Actions.AddressSearch, payload);
    } catch (error) {
      const errorDescription = (error.response && error.response.data)
        ? error.response.data.message
        : error.message;
      throw new Error(errorDescription);
    }
  },
  [ASS.Actions.DoSearchDetails]: async (context, payload) => {
    if (!context.state[ASS.StateVars.AxiosInstance]) {
      throw new Error('No Axios instance set');
    }
    if (payload.direction === 'origin') {
      context.commit(ASS.Mutations.SetLoadingOriginSuggestions, true);
    } else {
      context.commit(ASS.Mutations.SetLoadingDestinationSuggestions, true);
    }

    try {
      const { data: { code, message, result } } = await addressDetails(
        context.state[ASS.StateVars.AxiosInstance],
        payload.data.seoid,
      );

      if (code !== 200) {
        throw new Error(message);
      }

      if (payload.direction === 'origin') {
        context.commit(ASS.Mutations.SetDetailedOriginResults, result);
      } else {
        context.commit(ASS.Mutations.SetDetailedDestinationResults, result);
      }
    } catch (error) {
      const errorDescription = (error.response && error.response.data)
        ? error.response.data.message
        : error.message;
      throw new Error(errorDescription);
    } finally {
      if (payload.direction === 'origin') {
        context.commit(ASS.Mutations.SetLoadingOriginSuggestions, false);
      } else {
        context.commit(ASS.Mutations.SetLoadingDestinationSuggestions, false);
      }
    }
  },
  [ASS.Actions.SearchLastEnteredText]: async (context) => {
    try {
      await context.dispatch(ASS.Actions.DoSearch, context.state[ASS.StateVars.LastEnteredText]);
    } catch (error) {
      throw new Error(error);
    }
  },
};

const mutations = {
  ...MutationsUtility.scaffold(state, STATE_PROPS_TO_EXPOSE),
  [MUTATIONS.Reset]: ModuleUtilities.ResetToInitial(initialState),
  [ASS.Mutations.CancelAxiosAutocompleteSource]: (stateP) => {
    if (stateP[ASS.StateVars.AxiosAutocompleteSource]) {
      const localState = stateP;
      localState[ASS.StateVars.AxiosAutocompleteSource].cancel();
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
